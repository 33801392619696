import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/items', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOne(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/items/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    add(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/items', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, data) {
      return new Promise((resolve, reject) => {
        const url = `/items/${data.id}`
        axios
          .put(url, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    del(ctx, data) {
      return new Promise((resolve, reject) => {
        const url = `/items/${data.id}`
        axios
          .delete(url, {
            params: {
              branch_id: data.branch_id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSKU() {
      return new Promise((resolve, reject) => {
        axios
          .get('/pos/get-sku')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
