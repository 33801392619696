import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBranches(ctx, queryParams = { id_name: 1 }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/branches', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEmployees(ctx, queryParams = { id_name: 1 }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/options/employee', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsers(ctx, queryParams = { id_name: 1 }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategories(ctx, queryParams = { id_name: 1 }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/categories', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUnits(ctx, queryParams = { id_name: 1 }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/units', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItems(ctx, queryParams = { id_name: 1 }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/items/options/get', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPosItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/pos/items', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPosClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/pos/clients', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPosEmployees(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/pos/employees', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLedgers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/options/ledger', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTags(ctx, queryParams = { id_name: 1 }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/tags', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReasons(ctx, queryParams = { id_name: 1 }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/reasons', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClients(ctx, queryParams = { id_name: 1 }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/clients', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSaleRegions(ctx, queryParams = { id_name: 1 }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/sale-regions', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSuppliers(ctx, queryParams = { id_name: 1 }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/suppliers', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
